import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SuperadminDashboardComponent } from './superadmin-dashboard/superadmin-dashboard.component';
import { UserGuard } from './user.guard';
import { TellerGuard } from './teller.guard';
import { AdminGuard } from './admin.guard';
import { SuperadminGuard } from './superadmin.guard';
import { HomeComponent } from './home/home.component';
import { ForgotComponent } from './forgot/forgot.component';
import { DepartmentGuard } from './department.guard';
import { CampingViewComponent } from './camping-view/camping-view.component';
import { SubAdminDashboardComponent } from './subadmin-dashboard/subadmin-dashboard.component';
import { SubAdminGuard } from './subadmin.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'dashboard', component: CampingViewComponent, canActivate: [UserGuard] },
  { path: 'subadmin', component: SubAdminDashboardComponent, canActivate: [SubAdminGuard] },
  { path: 'admin', component: AdminDashboardComponent, canActivate: [AdminGuard] },
  { path: 'superadmin', component: SuperadminDashboardComponent, canActivate: [SuperadminGuard] },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
