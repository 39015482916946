import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppGlobals } from './app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient,
    private appGlobals: AppGlobals
  ) { }

  login(email: string, password: string): Promise<any> {
    return this.http.post('/api/login', JSON.stringify({ email: email, password: password }), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
    .toPromise()
    .then(result => {
      let helper = new JwtHelperService();
      localStorage.setItem('token', result['token']);
      this.appGlobals.user = helper.decodeToken(result['token']).data;

      return this.appGlobals.user;
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('token');
      resolve();
    });
  }

  forgot(email: string): Promise<any> {
    return this.http.post('/api/forgot', JSON.stringify({ email: email }), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
    .toPromise()
  }

  createUserAccount(userId: number, name: string): Promise<any> {
    return this.http.post<any>('/api/users/' + userId + '/accounts', {name: name}, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  createUser(data: any): Promise<any> {
    return this.http.post<any>('/api/users', JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).toPromise();
  }

  listUsers(params: ParamMap): Observable<any> { 
    return this.http.get<any>('/api/users?' + params.keys.map(o => { return o + '=' + params.get(o) }).join('&'), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    });
  }

  getUser(userId: number): Promise<any> { 
    return this.http.get<any>('/api/users/' + userId, {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }

  updateUser(user: any): Promise<any> {
    return this.http.put<any>('/api/users/' + user.id, JSON.stringify(user), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  getAccounts(params: ParamMap): Observable<any> {
    return this.http.get<any>('/api/accounts?' + params.keys.map(o => { return o + '=' + params.get(o) }).join('&'), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    });
  }
  
  getAccount(id: any): Promise<any> {
    return this.http.get<any>('/api/accounts/' + id, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  transferFunds(userId: number, from: any, to: any, amount: any): Promise<any> { 
    return this.http.post<any>('/api/users/' + userId + '/transfer', JSON.stringify({
        from: from,
        to: to,
        amount: amount
    }), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }

  grantAccountOTA(userId: number, role: number): Promise<any> {
    return this.http.post<any>('/api/users/' + userId + '/ota', JSON.stringify({
      to: userId,
      role: role
    }), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }
  
  updateBank(bank: any): Promise<any> {
    return this.http.put<any>('/api/bank', JSON.stringify(bank), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  getDepartment(id: any): Promise<any> {
    return this.http.get<any>('/api/department/' + id, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  addDepartmentSchedule(departmentId: number, schedule: any): Promise<any> {
    return this.http.post<any>('/api/department/' + departmentId + '/schedule', JSON.stringify(schedule), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }
  
  updateDepartmentSchedule(departmentId: number, schedule: any): Promise<any> {
    return this.http.put<any>('/api/department/' + departmentId + '/schedule/' + schedule.id, JSON.stringify(schedule), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  removeDepartmentSchedule(departmentId: number, scheduleId: any): Promise<any> {
    return this.http.delete<any>('/api/department/' + departmentId + '/schedule/' + scheduleId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  payDepartmentSchedule(departmentId: number, scheduleId: number, amount: number): Promise<any> {
    return this.http.post<any>('/api/department/' + departmentId + '/schedule/' + scheduleId + '/pay', JSON.stringify({amount: amount}), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
  
  addDepartmentUser(departmentId: number, userId: any, role: any): Promise<any> {
    return this.http.post<any>('/api/department/' + departmentId + '/users', JSON.stringify({user: userId, role: role}), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }
  
  updateDepartmentUser(departmentId: number, user: any): Promise<any> {
    return this.http.put<any>('/api/department/' + departmentId + '/user/' + user.id, JSON.stringify(user), {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }
  
  removeDepartmentUser(departmentId: number, userId: any): Promise<any> {
    return this.http.delete<any>('/api/department/' + departmentId + '/user/' + userId, {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    }).toPromise();
  }

  updateCampingConfiguration(data: any): Promise<any> {
    return this.http.put<any>('/api/config.json', JSON.stringify(data), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  listAvailableCamping(): Promise<any> {
    return this.http.get<any>('/api/camping/available', {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  listUnassignedCampers(): Promise<any> {
    return this.http.get<any>('/api/users/unassigned', {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  listCamping(search: any = ''): Promise<any> {
    return this.http.get<any>('/api/camping?search=' + search, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  listUserCamping(userId: any): Promise<any> {
    return this.http.get<any>('/api/users/' + userId + '/camping', {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  getCamping(campingId: any): Promise<any> {
    return this.http.get<any>('/api/camping/' + campingId, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  createCamping(data: any): Promise<any> {
    return this.http.post<any>('/api/camping', JSON.stringify(data), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  deleteCamping(campingId: any): Promise<any> {
    return this.http.delete<any>('/api/camping/' + campingId, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  purchaseCamping(campingId: any, owner: string, account: any): Promise<any> {
    return this.http.post<any>('/api/camping/' + campingId + '/purchase', JSON.stringify({owner: owner, account: account}), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  updateCamping(campingId: any, data: any): Promise<any> {
    return this.http.put<any>('/api/camping/' + campingId, JSON.stringify(data), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  addUserToCamping(campingId:any, userId:any): Promise<any> {
    return this.http.post<any>('/api/camping/' + campingId + '/users/' + userId, JSON.stringify({}), {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }

  removeUserFromCamping(campingId:any, userId:any): Promise<any> {
    return this.http.delete<any>('/api/camping/' + campingId + '/users/' + userId, {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).toPromise();
  }
}
