import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { AppGlobals } from './app.globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  constructor(
    private router: Router,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngInit() {
    //document.title = this.appGlobals.bank.name;
  }

  logout() {
    this.appService.logout();
    window.location.href = 'https://www.blacksailsadventures.org/';
  }

  toggleNav() {
    document.getElementById('navbarNav').classList.toggle('collapse');
  }
}
