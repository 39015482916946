import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { convertToParamMap } from '@angular/router';

@Component({
  selector: 'app-subadmin-dashboard',
  templateUrl: './subadmin-dashboard.component.html',
  styleUrls: ['./subadmin-dashboard.component.css']
})
export class SubAdminDashboardComponent implements OnInit {
  purchased: any = [];
  available: any = [];
  accounts: any = [];
  item: any = { location: '', owner: '', account: '' };
  searchstr: any = '';

  accountSearchResults: any = [];
  searching = false;
  
  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.appService.listCamping()
      .then(results => {
        this.purchased = results;
        return this.appService.getUser(this.appGlobals.user.id);
      }).then(results => {
          this.accounts = results.accounts;
          this.item.account = this.accounts[0].id;
      }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  doSearchKeyUp(evnt) {
    if (evnt.keyCode == 13)
      this.search()
  }

  customComparator = (a, b) => a.localeCompare(b, 'en', { numeric: true })

  search() {
    this.appService.listCamping(this.searchstr)
    .then(results => {
      this.purchased = results;
    })
    .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  updateCamping(camping) {
    if (!confirm("Are you sure you want to update '" + camping.location + "'?")) {
      this.ngOnInit();
      return;
    } else {
      this.appService.updateCamping(camping.id, camping)
        .then(result => this.toastrService.success("Updated!"))
        .catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  addMember(item:any) {
    this.appService.addUserToCamping(item.id, item.newMember.id).then(result => {
        item.members.push(result);
        item.newMember = null;
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  removeMember(item:any, member:any) {
    if (!confirm("Are you sure you want to remove '" + member.nickname + "' from this camping location?"))
      return;
    this.appService.removeUserFromCamping(item.id, member.id).then(result => {
        item.members.splice(item.members.indexOf(member), 1);
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  downloadCampingSpotList() {
    var ret = '"Location","House/Ship","Campers"\r\n';
    for (var c = 0; c < this.purchased.length; c++) {
      ret += `"${this.purchased[c].location}","${this.purchased[c].owner || ''}","${this.purchased[c].members.length}"\r\n`
    }
    var fileData = new Blob([ret], {type: 'text/plain'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //for IE
      window.navigator.msSaveOrOpenBlob(fileData, 'CampingSpots.csv');
    } else { // for other browsers (chrome, Firefox, Etc.)}
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = 'none';
        var pdfUrl = URL.createObjectURL(fileData);
        a.href = pdfUrl;
        a.download = 'CampingSpots.csv';
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
    }
  }

  downloadCampingList() {
    var ret = '"Pirate/Kink Name","Location","House/Ship"\r\n';
    for (var c = 0; c < this.purchased.length; c++) {
      for (var u = 0; u < this.purchased[c].members.length; u++) {
        ret += `"${this.purchased[c].members[u].nickname}","${this.purchased[c].location}","${this.purchased[c].owner}"\r\n`
      }
    }
    var fileData = new Blob([ret], {type: 'text/plain'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //for IE
      window.navigator.msSaveOrOpenBlob(fileData, 'Campers.csv');
    } else { // for other browsers (chrome, Firefox, Etc.)}
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = 'none';
        var pdfUrl = URL.createObjectURL(fileData);
        a.href = pdfUrl;
        a.download = 'Campers.csv';
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
    }
  }

  accountSearch = (text$: Observable<string>) => 
  text$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    filter(o => o.trim().length >= 3),
    tap(() => this.searching = true),
    switchMap(term => 
      this.appService.listUsers(convertToParamMap({search: term.trim()})).pipe(
        catchError(() => {
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  )

  formatter = (x: {id: number, nickname: string}) => x.nickname;

  checkin(item: any) {
    if (confirm(`Are you sure you want to check-in '${item.location}`)) {
      item.checkin = new Date().toISOString();
      this.appService.updateCamping(item.id, item)
        .then(result => this.toastrService.success("Updated!"))
        .catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  checkout(item: any) {
    if (confirm(`Are you sure you want to check-out '${item.location}`)) {
      item.checkout = new Date().toISOString();
      this.appService.updateCamping(item.id, item)
        .then(result => this.toastrService.success("Updated!"))
        .catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  firepermit(item: any) {
    if (confirm(`Are you sure you want to give a fire permit to '${item.location}`)) {
      item.firepermit = new Date().toISOString();
      this.appService.updateCamping(item.id, item)
        .then(result => this.toastrService.success("Updated!"))
        .catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }
}
