import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotkeysService } from 'angular2-hotkeys';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ActivatedRoute, convertToParamMap } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-camping-view',
  templateUrl: './camping-view.component.html',
  styleUrls: ['./camping-view.component.css']
})
export class CampingViewComponent implements OnInit {

    purchased: any = [];
    available: any = [];
    accounts: any = [];
    item: any = { location: '', owner: '', account: '' };
    user: any = {};

    accountSearchResults: any = [];
    searching = false;
  
    constructor(
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        public appGlobals: AppGlobals,
        private appService: AppService,
        private modalService: NgbModal,
        private domSanitizer: DomSanitizer
    ) { }
    
    ngOnInit() {
        this.appService.listAvailableCamping().then(result => {
            this.available = result;
            return this.appService.listUserCamping(this.appGlobals.user.id);
        }).then(result => {
            return Promise.all(result.map(o => this.appService.getCamping(o.id)));
        }).then(result => {
            this.purchased = result;
            console.log(this.purchased)
            return this.appService.getUser(this.appGlobals.user.id);
        }).then(results => {
            this.user = results;
            this.accounts = results.accounts;
            if (this.hasVoucher())
                this.item.account = "voucher";
            else
                this.item.account = this.accounts[0].id;
        }).catch(err => this.toastrService.error(err.error || err.statusText || err));
    }

    customComparator = (a, b) => a.localeCompare(b, 'en', { numeric: true })

    mapURL = () => this.domSanitizer.bypassSecurityTrustResourceUrl(this.appGlobals.camping.map_url);

    mapURLEmpty = () => this.appGlobals.camping.map_url == "";

    getInstructions = () => this.domSanitizer.bypassSecurityTrustHtml(this.appGlobals.camping.instructions);
        

    accountSearch = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 3),
      tap(() => this.searching = true),
      switchMap(term => 
        this.appService.listUsers(convertToParamMap({search: term.trim()})).pipe(
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

    formatter = (x: {id: number, nickname: string}) => x.nickname;

    getLocationNameById(id) {
        return this.available.find(o => o.id == this.item.location).location;
    }
    purchase(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.appService.purchaseCamping(this.item.location, this.item.owner, this.item.account).then(result => {
                this.purchased.push(result);
                this.item = { location: '', owner: '', account: this.accounts[0].id };
            }).catch(err => this.toastrService.error(err.error || err.statusText));
        }, (reason) => {
           
        });
    }
    /*purchase() {
        if (!confirm('Are you sure you wish to purchase camping location \'' + this.available.find(o => o.id == this.item.location).location + '\'? You may add/remove campers once you have completed the purchase. Refunding your purchase will require contacting the Port Authority and may not be possible based on timing to the event.'))
            return;
        this.appService.purchaseCamping(this.item.location, this.item.owner, this.item.account).then(result => {
            this.purchased.push(result);
            this.item = { location: '', owner: '', account: this.accounts[0].id };
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }*/

    addMember(item:any) {
        this.appService.addUserToCamping(item.id, item.newMember.id).then(result => {
            item.members.push(result);
            item.newMember = null;
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }

    removeMember(item:any, member:any) {
        this.appService.removeUserFromCamping(item.id, member.id).then(result => {
            item.members.splice(item.members.indexOf(member), 1);
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }

    hasVoucherOrFunds(): boolean {
        if (this.accounts.length == 0) return false;
        if (this.accounts[0].balance >= 60)
            return true;
        if (this.purchased.length == 0 && this.user.hasCampingVoucher == 1)
            return true;
        if (this.purchased.length == 0 && this.user.schedule != undefined && this.user.schedule.length != 0 && this.user.schedule.map(o=>o.pay).reduce((sum, a) => sum + a) >= 60)
            return true;
        return false;
    }

    hasVoucher(): boolean {
        if (this.purchased.length == 0 && this.user.hasCampingVoucher == 1)
            return true;
        if (
            this.purchased.length == 0 && 
            this.accounts[0].balance < 60 && 
            this.user.hasCampingVoucher == 0 && 
            this.user.schedule != undefined && this.user.schedule.length != 0 && this.user.schedule.map(o=>o.pay).reduce((sum, a) => sum + a) >= 60
        )
            return true;
        return false;
    }
}